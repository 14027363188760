<template lang="html">
  <v-dialog v-model="dialogFeedback" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="item.validasi == 1 && !item.is_feedback"
      class="ma-1"
      small
      color="yellow"
      v-bind="attrs"
      v-on="on"
      @click="feedbackItem(item)">
      <v-icon small left>mdi-forum</v-icon>Feedback Evaluasi</v-btn>
      <div v-else-if="item.validasi == 1" class="font-weight-medium"><i>Feedback</i> sudah divalidasi</div>
    </template>
    <v-card>
      <v-card-text>
        <v-form ref="formFeedback">
          <h1 class="display-2 my-2">Feedback Evaluasi Sasaran Kinerja Pegawai</h1>
          <div class="my-2">
            <span class="label font-weight-light">Jenis</span>
            <div class="font-weight-regular">Tahunan</div>
          </div>
          <div class="my-2">
            <span class="label font-weight-light">kegiatan</span>
            <div class="font-weight-regular">{{ defaultStrip(formFb.kegiatan) }}</div>
          </div>
          <div class="my-2">
            <span class="label font-weight-light">Sasaran Kinerja Tahunan</span>
            <div class="font-weight-regular">{{defaultStrip(formFb.sasaran_kinerja_tahunan)}}</div>
          </div>
          <v-divider></v-divider>

          <h1 class="display-2 my-2">Indikator Kinerja</h1>

          <v-row>
            <v-col>
                <span class="label font-weight-light">Target</span>
                <div v-if="formFb.target != null" class="font-weight-regular">{{defaultStrip(formFb.target)}} {{formFb.satuan_target}}</div>
                <div v-else-if="formFb.target_bawah != null" class="font-weight-regular">{{defaultStrip(formFb.target_bawah)}} {{formFb.satuan_target}}</div>
                <div v-else-if="formFb.kualitas_batas_bawah != null" class="font-weight-regular">{{defaultStrip(formFb.kualitas_batas_bawah)}} {{formFb.satuan_target}}</div>
                <div v-else-if="formFb.waktu_batas_bawah != null" class="font-weight-regular">{{defaultStrip(formFb.waktu_batas_bawah)}} {{formFb.satuan_target}}</div>
                <!-- <div class="font-weight-regular">{{defaultStrip(formFb.target)}} {{formFb.satuan_target}}</div> -->
            </v-col>
            <v-col>
                <span class="label font-weight-light">Realisasi</span>
                <div v-if="formFb.realisasi != null" class="font-weight-regular">{{defaultStrip(formFb.realisasi)}} {{formFb.satuan_realiasi}}</div>
                <div v-else-if="formFb.realisasi_kuantitas != null" class="font-weight-regular">{{defaultStrip(formFb.realisasi_kuantitas)}} {{formFb.satuan_realiasi}}</div>
                <div v-else-if="formFb.realisasi_kualitas != null" class="font-weight-regular">{{defaultStrip(formFb.realisasi_kualitas)}} {{formFb.satuan_realiasi}}</div>
                <div v-else-if="formFb.realisasi_waktu != null" class="font-weight-regular">{{defaultStrip(formFb.realisasi_waktu)}} {{formFb.satuan_realiasi}}</div>
                <!-- <div class="font-weight-regular">{{defaultStrip(formFb.realisasi)}} {{formFb.satuan_realiasi}}</div> -->
            </v-col>
          </v-row>

          <div class="my-2">
            <span class="label font-weight-light">Capaian</span>
            <div class="font-weight-regular">{{defaultStrip(formFb.capaian)}}</div>
          </div>

          <v-row>
            <v-col>
                <span class="label font-weight-light">Nilai Kualitas Mutu</span>
                <div v-if="formFb.kualitas_mutu <= 5">
                  <v-rating
                    v-model="formFb.kualitas_mutu"
                    background-color="orange lighten-3"
                    :color="'orange darken-4'"
                    size="30"
                    :hover=true
                    :readonly="true"
                  >
                  </v-rating>
                </div>
                <div v-else>
                  <label class="control-label" style="text-align:left" >{{formFb.kualitas_mutu}}</label>
                </div>
                <!-- <div class="font-weight-regular">{{defaultStrip(formFb.kualitas_mutu)}}</div> -->
            </v-col>
            <v-col>
                <span class="label font-weight-light">Nilai Pembalik</span>
                <div class="font-weight-regular">{{defaultStrip(formFb.np)}}</div>
            </v-col>
            <v-col v-if="formFb.waktu != null">
                <span class="label font-weight-light">Waktu</span>
                <div class="font-weight-regular">{{defaultStrip(formFb.waktu)}} {{formFb.satuan_waktu}}</div>
            </v-col>
            <v-col>
                <span class="label font-weight-light">Kualitas</span>
                <div class="font-weight-regular">{{defaultStrip(formFb.kualitas)}} %</div>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <h1 class="display-2 my-2">Laporan Data, Pengukuran Kinerja, Evaluasi/Arahan Pimpinan</h1>

          <div class="my-2">
            <span class="label font-weight-light">Formulasi Pengukuran, Alasan, dan Kriteria</span>
            <div class="font-weight-regular">{{defaultStrip(formFb.formulasi)}}</div>
          </div>

          <div class="my-2">
            <span class="label font-weight-light">Data Yang Relevan Dengan Target Kinerja Tahunan</span>
            <div class="font-weight-regular">{{defaultStrip(formFb.data_relevan)}}</div>
          </div>

          <div class="my-2">
            <span class="label font-weight-light">Pengukuran Kinerja</span>
            <div class="font-weight-regular">{{defaultStrip(formFb.pengukuran_kinerja)}}</div>
          </div>

          <v-textarea :value="formFb.evaluasi_analisis_kinerja ? formFb.evaluasi_analisis_kinerja : formFb.umpan_balik"  disabled outlined label="Evaluasi Analisis Kinerja/Umpan Balik Atasan Anda"></v-textarea>

          <v-textarea v-model.lazy="formFb.feedback_evaluasi_analisis_kinerja"  outlined label="Feedback Evaluasi Analisis Kinerja" required :rules="[rules.required, rules.min30]"></v-textarea>

          <!-- <v-divider></v-divider>
          <h1 class="display-2 my-2">Indikator Keuangan</h1>
          <div class="my-2">
            <span class="label font-weight-light">Kegiatan</span>
            <div class="font-weight-regular">{{defaultStrip(formFb.kegiatan)}}</div>
          </div>
          <div class="my-2">
            <span class="label font-weight-light">Pagu</span>
            <div class="font-weight-regular">{{defaultStrip(formFb.pagu)}}</div>
          </div>
          <div class="my-2">
            <span class="label font-weight-light">Realisasi</span>
            <div class="font-weight-regular">{{defaultStrip(formFb.realisasi_pagu)}}</div>
          </div> -->
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="kirimFeedback()" :loading="loadingBtn.laporkan" class="success" dark>Laporkan</v-btn>
        <v-btn @click="dialogFeedback=false">Tutup</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import SKPService from '@/services/SKPService'
import store from '@/store'

export default {
  props:{
    item: Object,
    jenis:[String, Number]
  },
  data(){
    return {
      dialogFeedback:false,
      formFb:{},
      rules:{
        required:v=> !!v || v == 0 || 'Tidak boleh kosong',
        harus18:v=> v.length == 18 || 'NIP memerlukan 18 karakter',
        onlyInt:v=> !isNaN(v) || 'Hanya angka',
        min100: v=> (v && v.length > 100) || 'Minimal 100 karakter',
        min30: v=> (v && v.length > 30) || 'Minimal 30 karakter',
        min25: v=> (v && v.length > 25) || 'Minimal 25 karakter',
      },
      loadingBtn:{
        laporkan:false
      }
    }
  },
  methods:{
    defaultStrip(item){
      if (!item){
        return '-'
      }
      return item
    },

    feedbackItem(item){
      console.log(item)
      this.dialogFeedback = true
      item.evaluasi_analisis_kinerja = item.evaluasi_analisis_kinerja ? item.evaluasi_analisis_kinerja.replace(/\[e-([0-9a-fA-F]+)\]/g, (match, hex) => String.fromCodePoint(Number.parseInt(hex, 16))) : item.evaluasi_analisis_kinerja
      item.umpan_balik = item.umpan_balik ? item.umpan_balik.replace(/\[e-([0-9a-fA-F]+)\]/g, (match, hex) => String.fromCodePoint(Number.parseInt(hex, 16))) : item.umpan_balik
      this.formFb = _.clone(item)
      if(item.kualitas_mutu > 5){
        if(item.kualitas_mutu >= 0 && item.kualitas_mutu <= 0 ){
          this.formFb.kualitas_mutu = 0
        }else if(item.kualitas_mutu >= 0 && item.kualitas_mutu <= 50){
          this.formFb.kualitas_mutu = 1
        }else if(item.kualitas_mutu >= 51 && item.kualitas_mutu <= 60){
          this.formFb.kualitas_mutu = 2
        }else if(item.kualitas_mutu >= 61 && item.kualitas_mutu <= 75){
          this.formFb.kualitas_mutu = 3
        }else if(item.kualitas_mutu >= 76 && item.kualitas_mutu <= 90){
          this.formFb.kualitas_mutu = 4
        }else{
          this.formFb.kualitas_mutu = 5
        }
      }
      this.formFb.np = this.formFb.is_pembalik ? 'Ya' :'Tidak'
      this.$nextTick(()=>{
        if (this.$refs.formFeedback){
          this.$refs.formFeedback.resetValidation()
        }
      })
    },

    kirimFeedback(){
      if(this.$refs.formFeedback.validate()){
        // let evaluasi = this.formFb.feedback_evaluasi_analisis_kinerja
        // this.formFb.evaluasi_analisis_kinerja = evaluasi ? evaluasi : '-'
        console.log(this.formFb)
        SKPService.feedbackEvaluasi(this.formFb, this.jenis).then((response)=>{
          let res = response.data
          if (res.success){
            store.commit('snackbar/setSnack',{message:res.message, color:'success'})
            this.$emit('on-update-success')
            this.dialogFeedback = false
          }else {
            store.commit('snackbar/setSnack',{message:res.message, color:'error'})
          }
        })
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
