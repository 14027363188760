import { render, staticRenderFns } from "./FeedbackBtn.vue?vue&type=template&id=532f9b05&scoped=true&lang=html&"
import script from "./FeedbackBtn.vue?vue&type=script&lang=js&"
export * from "./FeedbackBtn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532f9b05",
  null
  
)

export default component.exports